var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "frmBlock__title"
  }, [_vm._v("내용 입력")]), _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "내용을 입력해주세요.",
      "value": _vm.values.text,
      "maxlength": 100
    },
    on: {
      "input": function input(v) {
        return _vm.updateOption('text', v);
      }
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__title frmBlock__title--last"
  }, [_vm._v(" URL "), _c('tooltip', {
    attrs: {
      "top": "30px",
      "text": "\uB3C4\uBA54\uC778\uC774 \uD3EC\uD568\uB41C URL\uC744 \uC785\uB825\uD558\uB294 \uACBD\uC6B0 https:// \uB610\uB294 http:// \uB97C \uD568\uAED8 \uC785\uB825\uD574\uC8FC\uC138\uC694.<br/>\n      \uFF65 \uC608) https://qpicki.com"
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__layout frmBlock__layout--grow"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "URL을 입력해주세요.",
      "value": _vm.values.url
    },
    on: {
      "input": function input(v) {
        return _vm.updateOption('url', v);
      }
    }
  }), _c('Dropdown', {
    attrs: {
      "value": _vm.values.target
    },
    on: {
      "updateTarget": function updateTarget(v) {
        return _vm.updateOption('target', v);
      }
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }