<template>
  <div>
    <div class="frmBlock__title">내용 입력</div>
    <div class="frmBlock__layout">
      <TextField
        placeholder="내용을 입력해주세요."
        :value="values.text"
        @input="v => updateOption('text', v)"
        :maxlength="100"
      />
    </div>
    <div class="frmBlock__title frmBlock__title--last">
      URL
      <tooltip
        top="30px"
        :text="`도메인이 포함된 URL을 입력하는 경우 https:// 또는 http:// 를 함께 입력해주세요.<br/>
        ･ 예) https://qpicki.com`"
      />
    </div>
    <div class="frmBlock__layout frmBlock__layout--grow">
      <TextField
        placeholder="URL을 입력해주세요."
        :value="values.url"
        @input="v => updateOption('url', v)"
      />
      <Dropdown
        :value="values.target"
        @updateTarget="v => updateOption('target', v)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      values: {} //for partner
    };
  },
  computed: {
    // initialValue() {
    //   return this.$route.path === "/option" ? this.values : this.option.value;
    // }
  },
  methods: {
    // 프로퍼티 업데이트 (파트너)
    updateProperty(text, url, target) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: text.id,
            key: text.key,
            initialValue: text.value
          },
          {
            id: url.id,
            key: url.key,
            initialValue: url.value
          },
          {
            id: target.id,
            key: target.key,
            initialValue: target.value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateOption(type, value) {
      this.$set(this.values, type, value);
      this.$emit("updateOption", this.option.componentOption.key, type, value);

      if (this.$route.path === "/option") {
        let mProperties = this.option.modified.properties;
        let obj = {};

        mProperties.forEach(item => {
          obj[item.key] = {
            id: item.id,
            key: item.key,
            value: type === item.key ? value : item.initialValue
          };
        });

        this.updateProperty(obj.text, obj.url, obj.target);
      }
    },
    //값 추출
    extractValue() {
      let isOption = this.$route.path === "/option" ? true : false;
      if (!isOption) {
        this.values = this.option.value;
        return false;
      }
      let obj = {};

      this.option.componentOption.properties.forEach(item => {
        switch (item.key) {
          case "target":
            this.$set(this.values, item.key, item.initialValue ?? "_self");
            obj[item.key] = {
              id: item.id,
              key: item.key,
              value: item.initialValue ?? "_self"
            };
            break;

          default:
            this.$set(this.values, item.key, item.initialValue);
            obj[item.key] = {
              id: item.id,
              key: item.key,
              value: item.initialValue
            };
            break;
        }
      });
      this.updateProperty(obj.text, obj.url, obj.target);
    }
  },
  created() {
    this.extractValue();
  }
};
</script>

<style lang="scss" scoped></style>
